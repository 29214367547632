<template>
  <svg
    width="8"
    height="14"
    viewBox="0 0 8 14"
    stroke="#7270C2"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.25 12.25L1 7L6.25 1.75"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
